import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { notify } from "core/helpers/notify";
import {
  getAllTripByDate,
  getAllTripInLocation,
  getAvailableTrips,
  getTripsInTransit,
  getUserBookedTrip,
  joinOngoingTrip,
  scheduleTrip,
  sharedTripBill,
} from "../api/transport.api";
import { getDate, isTimeNotPassed, isToday } from "core/helpers/generalHelpers";

type StoreState = {
  isLoading: boolean;
  bookedTrips: BookedTrip[];
  selectedBookedTrip: BookedTrip | null;
  availableTrips: Trip[];
  newTripSchedule: NewTripSchedule | null;
  locationTrips: LocationTrip[];
  tripsInTransit: Trip[];
  selectedTripInTransit: Trip | null;
  getLocationTrips: () => Promise<void>;
  getTripsInTransit: () => Promise<void>;
  getLocationTripsByDate: (departureDate: string) => Promise<void>;
  getUserBookedTrips: (startDate?: string, endDate?: string) => Promise<void>;
  getAvailableTrips: (
    departureDate?: string,
    tripId?: string,
  ) => Promise<UIResponse>;
  scheduleTrip: (
    trip: NewTripSchedule,
    hasWallet: boolean,
  ) => Promise<UIResponse>;
  joinOngoingTrip: (
    ongoingTrip: OngoingTripSchedule,
    hasWallet: boolean,
  ) => Promise<UIResponse>;
  shareTripBill: (bill: SharedTripBill) => Promise<UIResponse>;
  setSelectedBookedTrip: (trip: BookedTrip | null) => void;
  setNewTripSchedule: (trip: NewTripSchedule | null) => void;
  setTripInTransit: (trip: Trip | null) => void;
  clearBookedTrip: () => void;
  clearTripInTransit: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  bookedTrips: [],
  availableTrips: [],
  newTripSchedule: null,
  selectedBookedTrip: null,
  locationTrips: [],
  tripsInTransit: [],
  selectedTripInTransit: null,
};

const useTransportationStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        setSelectedBookedTrip: (trip) => {
          set({ selectedBookedTrip: trip });
        },
        setNewTripSchedule: (schedule) => {
          set({ newTripSchedule: schedule });
        },
        setTripInTransit: (trip) => {
          set({ selectedTripInTransit: trip });
        },
        getLocationTrips: async () => {
          set({ isLoading: true });

          var res = await getAllTripInLocation();

          if (res?.isSuccessful) {
            set({ locationTrips: res?.data?.data });
          } else {
            set({ locationTrips: [] });
          }

          set({ isLoading: false });
        },
        getTripsInTransit: async () => {
          set({ isLoading: true });

          var res = await getTripsInTransit();

          if (res?.isSuccessful) {
            set({ tripsInTransit: res?.data?.data });
          } else {
            set({ tripsInTransit: [] });
          }

          set({ isLoading: false });
        },
        getLocationTripsByDate: async (departureDate) => {
          set({ isLoading: true });

          var res = await getAllTripByDate(departureDate);

          if (res?.isSuccessful) {
            set({ locationTrips: res?.data?.data });
          } else {
            set({ locationTrips: [] });
          }

          set({ isLoading: false });
        },
        getAvailableTrips: async (departureDate, tripId) => {
          set({ isLoading: true });

          var res = await getAvailableTrips(departureDate, tripId);

          if (res?.data?.data?.length < 1) {
            set({ availableTrips: [] });
            notify({
              message: `There is currently no scheduled trip on this route for ${isToday(departureDate) ? "today" : getDate(departureDate)}, please choose a different route or schedule trip on the next available date`,
              type: "info",
            });
          } else {
            var trips: Trip[] = res?.data?.data;

            var validTrips = trips?.filter(
              (x) =>
                isTimeNotPassed(departureDate!, x.departureTime!) !== false,
            );

            set({ availableTrips: validTrips });
            res.data.data = validTrips;
            if (validTrips?.length < 1) {
              notify({
                message:
                  "All available trip departure time has passed. Please choose a different route",
                type: "info",
              });
            }
          }

          set({ isLoading: false });
          return res;
        },
        getUserBookedTrips: async (startDate, endDate) => {
          set({ isLoading: true });

          var res = await getUserBookedTrip(startDate, endDate);

          if (res?.isSuccessful) {
            set({ bookedTrips: res?.data?.data });
          } else {
            set({ bookedTrips: [] });
          }
          set({ isLoading: false });
        },
        scheduleTrip: async (trip, hasWallet) => {
          set({ isLoading: true });

          var res = await scheduleTrip(trip, hasWallet);
          var message: any = "";

          if (res?.isSuccessful) {
            if (hasWallet) {
              message = "Your order has been placed successfully";
            } else {
              message = res?.data?.message;
            }
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        joinOngoingTrip: async (ongoingTrip, hasWallet) => {
          set({ isLoading: true });

          var res = await joinOngoingTrip(ongoingTrip, hasWallet);
          var message: any = "";

          if (res?.isSuccessful) {
            if (hasWallet) {
              message = "You have joined the bus trip successfully.";
            } else {
              message = res?.data?.message;
            }
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        shareTripBill: async (bill) => {
          set({ isLoading: true });

          var res = await sharedTripBill(bill);
          var message: any = "";

          if (res?.isSuccessful) {
            message = res?.data?.message;
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        clearBookedTrip: () => {
          set({
            newTripSchedule: null,
          });
        },
        clearTripInTransit: () => {
          set({
            selectedTripInTransit: null,
          });
        },
      }),
      {
        name: "transportStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useTransportationStore;
