import axios, { AxiosRequestConfig, Method, ResponseType } from "axios";
import { clearSessionAndLogout } from "core/helpers/generalHelpers";
import { notify } from "core/helpers/notify";

interface ApiCallProps {
  endpoint: string;
  extra?: string;
  method: Method;
  body?: object;
  pQuery?: Record<string, string | number | boolean> | any; // Updated to include more types
  param?: string;
  multipart?: boolean;
  responseType?: ResponseType;
  auth?: boolean;
  isBaseUrl?: boolean;
}

export const apiCall = async ({
  endpoint,
  extra = "",
  method = "GET",
  body = {},
  pQuery = {},
  param = "",
  multipart = false,
  responseType = "json",
  auth = false,
  isBaseUrl = true,
}: ApiCallProps) => {
  const headers: any = {
    "X-API-KEY": process.env.REACT_APP_API_KEY,
    "Content-Type": multipart ? "multipart/form-data" : "application/json",
  };

  let url = `${isBaseUrl ? process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_SOURCE_ONE_URL}${endpoint}`;

  // Appending additional path segments
  if (extra) {
    url += `/${extra}`;
  }

  if (param) {
    url += `/${param}`;
  }

  // Handling query parameters
  if (Object.keys(pQuery).length) {
    const params = new URLSearchParams();

    Object.entries(pQuery).forEach(([key, value]) => {
      if (value !== undefined) {
        // Ensure undefined values are not appended
        params.append(key, String(value));
      }
    });

    url += `?${params.toString()}`;
  }

  // Authorization handling
  if (auth) {
    const userStore = sessionStorage.getItem("userStore");

    if (userStore) {
      const { state } = JSON.parse(userStore);
      if (state?.token) {
        headers.Authorization = `Bearer ${state.token}`;
      }
    }
  }

  const options: AxiosRequestConfig = {
    url,
    method,
    headers,
    responseType,
    data: body,
  };

  try {
    const response = await axios(options);

    return {
      isSuccessful: true,
      status: response.status,
      message: "Request processed successfully",
      data: response.status === 204 ? null : response.data,
    };
  } catch (error: any) {
    if (error?.message === "Network Error") {
      return {
        isSuccessful: false,
        status: error?.status,
        message: "Please check your internet connection",
        data: null,
      };
    }

    if (error?.response?.status === 401) {
      notify({
        type: "warning",
        message: "Please logout and sign in again",
      });

      clearSessionAndLogout();
    }

    return {
      isSuccessful: false,
      status: error?.response?.status,
      message: error?.response?.data?.message || "An error occurred",
      data: error?.response?.data,
    };
  }
};
