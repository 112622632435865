import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  checkEligibility,
  getActiveLoan,
  getLoanRequestById,
  getLoanRequestHistory,
  loanRepayment,
  loanRepaymentSchedule,
  loanRequest,
} from "../api/loans.api";
import { notify } from "core/helpers/notify";

type LoanState = {
  isLoading: boolean;
  loanHistoryList: LoanRequestList;
  activeLoanRequest: LoanRequest | null;
  singleLoanRequest: LoanRequest | null;
  userEligibility: UserEligibility | null;
  repaymentSchedule: LoanRepayment[];
  getEligibility: () => Promise<UIResponse>;
  calculateRepaymentSchedule: (
    amount: number,
    tenorInMonths: number,
  ) => Promise<void>;
  getActiveLoan: () => Promise<void>;
  getLoanRequestById: (id: string) => Promise<void>;
  getLoanRequests: (
    query: LoanQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  makeRepayment: (amount: number) => Promise<UIResponse>;
  placeLoanRequest: (data: LoanRequestData) => Promise<UIResponse>;
  reset: () => void;
};

const initialState = {
  isLoading: false,
  loanHistoryList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
  },
  activeLoanRequest: null,
  singleLoanRequest: null,
  userEligibility: null,
  repaymentSchedule: [],
};

const useLoanStore = create<LoanState>()(
  devtools(
    persist(
      (set, get): LoanState => ({
        ...initialState,
        getEligibility: async () => {
          set({ isLoading: true });

          var res: UIResponse = await checkEligibility();
          if (res?.isSuccessful) {
            set({
              userEligibility: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ userEligibility: null, isLoading: false });
          }

          return res;
        },
        getActiveLoan: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getActiveLoan();
          if (res?.isSuccessful) {
            set({
              activeLoanRequest: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ activeLoanRequest: null, isLoading: false });
          }
        },
        getLoanRequestById: async (id) => {
          set({ isLoading: true });

          var res: UIResponse = await getLoanRequestById(id);
          if (res?.isSuccessful) {
            set({
              singleLoanRequest: res?.data?.data,
              isLoading: false,
            });
          } else {
            notify({
              message: res?.isSuccessful
                ? res?.data?.message
                : res?.data?.error?.message?.length > 1
                  ? res?.data?.error?.message
                  : res?.data?.message,
              type: res?.isSuccessful ? "success" : "danger",
            });
            set({ singleLoanRequest: null, isLoading: false });
          }
        },
        getLoanRequests: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getLoanRequestHistory(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              loanHistoryList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        makeRepayment: async (amount) => {
          set({ isLoading: true });

          var res: UIResponse = await loanRepayment(amount);
          var currentRequest = get().singleLoanRequest;

          if (res?.isSuccessful) {
            get().getLoanRequestById(currentRequest?.id!);
          } else {
            set({ isLoading: false });
          }

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        calculateRepaymentSchedule: async (amount, tenorInMonths) => {
          set({ isLoading: true });

          var res: UIResponse = await loanRepaymentSchedule(
            amount,
            tenorInMonths,
          );
          if (res?.isSuccessful) {
            set({
              repaymentSchedule: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ repaymentSchedule: [], isLoading: false });
          }
        },
        placeLoanRequest: async (data) => {
          set({ isLoading: true });

          var res = await loanRequest(data);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        reset: () => {
          set({
            ...initialState,
          });
        },
      }),
      {
        name: "loanStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useLoanStore;
