export const btn =
  "flex items-center justify-center disabled:cursor-not-allowed gap-2 px-[26px] py-[20px] text-[14px] rounded-[40px] transition duration-300 ease-in-out";

export const step =
  "border-1 flex h-[40px] w-[40px] items-center justify-center rounded-full border text-[14px] font-bold";

export const activeStep = "border-brand bg-brand-200 text-brand";

export const inactiveStep = "border-gray-500 bg-gray-200 text-gray-500";

export const actionBtn =
  "rounded-[25px] text-black border border-[1px] border-brand px-[30px] py-[5px] text-[12px] shadow-md disabled:cursor-not-allowed";

export const navBtn =
  "text-brand disabled:cursor-not-allowed bg-white border border-[1px] border-[#DFA1F4] py-1 px-4 font-[400] hover:bg-brand hover:text-white";

export const otpInput =
  "h-[54px] !w-[54px] rounded-[8px] outline-none bg-[#F7F5FA] font-[600]";

export const otpBox = "my-3 w-full flex gap-[10px] justify-center items-center";

export const activeDashboardLink =
  "flex flex-col w-1/4 justify-center border-t-[3px] border-t-purple text-[10px] h-[80px] text-purple items-center hover:text-purple";

export const dashboardLink =
  "flex flex-col w-1/4 border-t-[3px] border-t-transparent justify-center text-[10px] h-[80px] items-center hover:text-purple";

export const purpleBox = "text-[#31054E] rounded-[4px] bg-[#F2EFF6] p-4";
