import React from "react";

const LoansBooking = React.lazy(() => import("./LoansBooking"));
const LoansDashboard = React.lazy(() => import("./LoansDashboard"));
const LoansOffer = React.lazy(() => import("./LoansOffer"));
const LoansDetail = React.lazy(() => import("./LoansDetail"));

const loansRoutes: RoutesType[] = [
  {
    name: "Loan Booking",
    layout: "/portal",
    path: "loans/booking",
    icon: "",
    component: <LoansBooking />,
  },
  {
    name: "Loan Details",
    layout: "/portal",
    path: "loans/detail/:id",
    icon: "",
    component: <LoansDetail />,
  },
  {
    name: "Loans Offer",
    layout: "/portal",
    path: "loans/offer",
    icon: "",
    component: <LoansOffer />,
  },
  {
    name: "Loans Dashboard",
    layout: "/portal",
    path: "loans",
    icon: "",
    component: <LoansDashboard />,
  },
];

export default loansRoutes;
