import { apiCall } from "./apiCall";

export const getActiveLoan = () => {
  return apiCall({
    endpoint: "/loan/activeRequest",
    method: "GET",
    auth: true,
  });
};

export const getLoanRequestById = (id: string) => {
  return apiCall({
    endpoint: "/loan/requestById",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const checkEligibility = () => {
  return apiCall({
    endpoint: "/loan/checkEligibility",
    method: "GET",
    auth: true,
  });
};

export const getLoanRequestHistory = (
  query: LoanQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apiCall({
    endpoint: "/loan/requestHistory",
    method: "GET",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    auth: true,
  });
};

export const loanRepayment = (amount: number) => {
  return apiCall({
    endpoint: "/loan/repayment",
    method: "POST",
    body: {
      amount,
    },
    auth: true,
  });
};

export const loanRepaymentSchedule = (
  amount: number,
  tenorInMonths: number,
) => {
  return apiCall({
    endpoint: "/loan/repaymentSchedule",
    method: "POST",
    body: {
      amount,
      tenorInMonths,
    },
    auth: true,
  });
};

export const loanRequest = (data: LoanRequestData) => {
  const formData = new FormData();

  formData.append("RequestAmount", data?.requestAmount?.toString());
  formData.append("TenorInMonths", data?.tenorInMonths?.toString());
  formData.append("BVN", data?.bvn);

  if (data?.loanFiles?.length > 0) {
    data?.loanFiles.forEach((file: any, i: any) => {
      formData.append(`loanfiles${i}`, file);
    });
  }

  return apiCall({
    endpoint: "/loan/request",
    method: "POST",
    auth: true,
    body: formData,
    multipart: true,
  });
};
