import Loader from "core/components/Loader";
import CustomLoader from "core/components/Loader";
import { ScrollToTop } from "core/helpers/scrollToTop";
import useFoodStuffStore from "core/services/stores/useFoodStuffStore";
import useLoanStore from "core/services/stores/useLoanStore";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketplaceStore from "core/services/stores/useMarketplaceStore";
import useOrderState from "core/services/stores/useOrderStore";
import useReferralStore from "core/services/stores/useReferralStore";
import useReviewStore from "core/services/stores/useReviewStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import useWalletStore from "core/services/stores/useWalletStore";
import Auth from "modules/auth/Auth";
import Portal from "modules/portal/Portal";
import { Suspense } from "react";
import { ReactNotifications } from "react-notifications-component";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const App = () => {
  const isUserLoading = useUserStore((store) => store.isLoading);
  const isLocationLoading = useLocationStore((store) => store.isLoading);
  const isWalletLoading = useWalletStore((store) => store.isLoading);
  const isReferralLoading = useReferralStore((store) => store.isLoading);
  const isMarketplaceLoading = useMarketplaceStore((store) => store.isLoading);
  const isFoodStuffLoading = useFoodStuffStore((store) => store.isLoading);
  const isOrderLoading = useOrderState((store) => store.isLoading);
  const isReviewLoading = useReviewStore((store) => store.isLoading);
  const isTransportLoading = useTransportationStore((store) => store.isLoading);
  const isLoanLoading = useLoanStore((store) => store.isLoading);

  return (
    <>
      {(isUserLoading ||
        isLocationLoading ||
        isWalletLoading ||
        isMarketplaceLoading ||
        isReferralLoading ||
        isOrderLoading ||
        isReviewLoading ||
        isTransportLoading ||
        isLoanLoading ||
        isFoodStuffLoading) && <Loader />}
      <Router>
        <ScrollToTop />

        <ReactNotifications />

        <Suspense fallback={<CustomLoader />}>
          <Routes>
            <Route path="/portal/*" element={<Portal />} />
            <Route path="/*" element={<Auth />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default App;
